.sliderContenedor {
  margin: 0vh 8vh 0vh 8vh;
  overflow: hidden;
  text-align: center;
  height: 82vh;
}

.slider {
  display: flex;
  cursor: grab; /*manita*/
}
.item {
  height: auto; /*alto 100vh */
  width: auto; /*maximo de ancho*/
  object-fit: cover;
}

.item img {
  height: 80vh;
  width: 55vh;
  cursor: grab;
  pointer-events: none;
  margin: 0 1.5vh;
  object-fit: cover;
  border-radius: 3px;
}

.linkSerie {
  text-decoration: none;
  color: rgb(250, 247, 247);
  font-size: 1.25rem;
  background-color: rgba(24, 46, 46, 0.4);
  position: absolute;
  top: 68vh;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: inline-block;
  text-shadow: 3px 2px 5px rgb(2, 2, 21);
}
