.gridItem {
  background-color: rgba(255, 255, 255, 0);
  font-size: 1.25rem;
  text-align: center;
  padding: 2vh;
}

.gridItem img:hover {
  transform: scale(1.03);
  box-shadow: 0px 10px 20px rgba(251, 251, 251, 0.245);
}

img {
  width: 170px;
  height: 240px;
  border-radius: 10px;
}
