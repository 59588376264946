html {
  box-sizing: border-box;
  font-size: 15px;
  font-family: sans-serif;
  text-align: justify;
}
:root {
  --color-primary: #e7401b;
  --color-bg: #171d22;
  --color-bg-alt: rgba(41, 44, 52, 0.651);
  --color-text: rgb(255, 255, 255);
}

*,
*:before,
*:after {
  box-sizing: inherit;
  font-family: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

svg {
  fill: currentColor;
  vertical-align: middle;
}
.wrapper {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0rem;
  margin-bottom: 0rem;
  max-width: 1024px;
  padding: 0px 20px;
  border-radius: 22px;
  background-color: var(--color-bg-alt);
  color: var(--color-text);
}

@media (max-width: 759px) {
  .wrapper {
    padding: 16px;
    flex-direction: column;
  }
}

#img_icon {
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  width: 32px;
  height: 32px;
  border: 3px solid #dee1e3;
}
.movie {
  display: flex;
  gap: 2rem;
  margin-bottom: 0rem;
  margin-top: 2rem;
}
@media (max-width: 759px) {
  .movie {
    flex-direction: column;
  }
}
.movie_img img {
  min-width: 280px;
  max-width: 280px;
  height: auto;

  border-radius: 22px;
}
@media (max-width: 759px) {
  .movie_img img {
    min-width: 60%;
    max-width: 60%;
    height: 400px;
  }
}
.movie_info {
  margin-top: 1rem;
}
.movie_title {
  font-size: 2rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}
.movie_desc {
  color: #dee1e3;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.movie_details {
  margin-bottom: 1rem;
  margin-top: 0rem;
}
.movie_details h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.flex {
  display: flex;
  gap: 0.5rem;
}
@media (max-width: 759px) {
  .flex {
    flex-wrap: wrap;
  }
}
.flex li {
  color: #dee1e3;
  text-align: start;
}
.flex li span {
  color: #fff;
  display: inline-block;
}

.btn {
  border-radius: 30px;
  background-color: var(--color-primary);
  color: #fff;

  padding: 1rem 1.25rem;
  text-decoration: none;
  text-align: center;
}
.btn svg {
  margin-right: 4px;
} /* */
