.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
  justify-content: center;
  padding: 10vh 0vh 0vh 0vh;
}

.gridContainer a {
  text-decoration: none;
  font-size: 1.1rem;
  color: rgba(241, 220, 225, 0.804);
  text-shadow: -3px 5px 8px #060108;
}

.gridContainer img {
  object-fit: cover;
  box-shadow: 0px 4px 6px rgb(160, 159, 159);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}