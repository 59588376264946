body {
  /*background-image: url(imagine2.jpg);*/
  background-color: rgb(19, 38, 50);
  margin: 0 0 0 0;
}

::-webkit-scrollbar {
  width: 12px;
  background-image: transparent !important;
}

::-webkit-scrollbar-thumb {
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 12%;
  background-image: linear-gradient(
    180deg,
    rgba(240, 41, 240, 0.504) 0%,
    rgba(203, 61, 209, 0.40800070028011204) 50%,
    rgba(0, 64, 255, 0.405) 100%
  );
}
